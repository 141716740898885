import { j as a, T as xt, c as L, s as gt, L as ft, H as wt, I as kt, B as Tt, a as mt, b as Ct } from "./TextLink-CwxWjQVW.mjs";
import { A as tn, d as en, C as nn, J as on, e as rn, f as sn } from "./TextLink-CwxWjQVW.mjs";
import * as V from "react";
import { forwardRef as K, useMemo as H, useState as O, useRef as F, useCallback as C, useEffect as M, useId as P, useLayoutEffect as pt } from "react";
import { createPortal as vt } from "react-dom";
const Lt = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), rt = {}, jt = (e, t, n, o) => {
  const i = `${e}-${t ? "currency" : "noCurrency"}-${n}-${o}`;
  let s = rt[i];
  return s || (s = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: n,
    maximumFractionDigits: n,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...o ? {} : { useGrouping: !1 }
  }), rt[i] = s), s;
};
function et(e, t, n = {}) {
  const o = (n == null ? void 0 : n.precision) === void 0 ? 2 : n == null ? void 0 : n.precision, r = typeof t == "number" ? t || 0 : Lt(e, t), i = (n == null ? void 0 : n.thousandSeparator) ?? !0;
  return jt(e, !(n != null && n.format), o, i).format(r).replace(/\u00A0/, " ");
}
function it(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), n = parseFloat(t) || 0;
  return Math.round(n * 100);
}
function G(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
const qe = [
  "text",
  "number",
  "email",
  "password",
  "search",
  "tel",
  "date",
  "datetime-local",
  "time",
  "url",
  "color"
];
function st(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const Nt = K(
  ({
    label: e,
    locale: t = "de",
    name: n,
    id: o,
    min: r = 0,
    onChange: i,
    onBlur: s,
    onFocus: l,
    defaultValue: d,
    value: u,
    step: f,
    children: p,
    className: v,
    hideSteps: j,
    max: T,
    ...h
  }, _) => {
    const g = H(() => It(f), [f]), [k, I] = O(
      G(
        et(t, u ?? d ?? "", { precision: g, format: "%v", thousandSeparator: !1 }),
        g
      )
    ), B = H(() => st(T), [T]), c = H(() => st(r), [r]), [b, w] = O(null), $ = F(null), X = C(
      (x) => {
        if ($.current = x, !!_) {
          if (typeof _ == "function") return _(x);
          _.current = x;
        }
      },
      [_]
    ), m = C(
      (x) => {
        const A = at(x.target.value, t, g), Q = it(A);
        if (typeof B < "u" && Q > B || typeof c < "u" && Q < c) {
          const _t = x.target.selectionStart ? x.target.selectionStart : 0;
          return w(_t), x.preventDefault();
        }
        w(x.target.selectionStart);
        let nt = x.target.value;
        A.startsWith(x.target.value) || (nt = A);
        const ot = it(x.target.value);
        x.target.value && !isNaN(ot) && Q === ot && (i == null || i(x)), I(nt);
      },
      [t, g, c, B, i]
    ), N = C(
      (x) => {
        const A = at(x.target.value, t, g);
        A !== x.target.value && (x.target.value = A), w(null), s == null || s(x);
      },
      [s, g, t]
    ), y = C(
      (x) => {
        w(x.target.selectionStart), l == null || l(x);
      },
      [l]
    ), E = H(
      () => d ? { defaultValue: k } : { value: k },
      [d, k]
    );
    return M(() => {
      if (typeof d < "u") return;
      const x = u ?? d;
      if (typeof u > "u")
        return I("");
      const A = G(
        et(t, x, { precision: g, format: "%v", thousandSeparator: !1 }),
        g
      );
      I(A);
    }, [u, g, d, t]), M(() => {
      const x = (k == null ? void 0 : k.length) ?? 0, A = (b ?? 0) > x ? x : b;
      b === null || !$.current || document.activeElement !== $.current || $.current.setSelectionRange(A, A);
    }, [b, k]), /* @__PURE__ */ a.jsx(
      xt,
      {
        ...h,
        ...E,
        onFocus: y,
        onBlur: N,
        onChange: m,
        step: f,
        ref: X,
        id: o || n,
        name: n,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: L(v, { [gt.hideSteps]: j }),
        children: p
      }
    );
  }
);
Nt.displayName = "ClientAmountField";
function at(e, t, n = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const o = String(e), r = G(o, n), i = et(t, r, { precision: n, format: "%v", thousandSeparator: !1 }), s = G(i, n);
  if (!n)
    return s;
  const l = s.search(/[.,]/);
  if (l < 0)
    return s;
  const d = n - (s.length - 1 - l);
  return s.concat("0".repeat(d));
}
function It(e) {
  var n;
  return e === void 0 ? void 0 : ((n = String(e).split(".")[1]) == null ? void 0 : n.length) ?? 0;
}
const yt = "_checkmark_ciy57_1", At = "_checkboxContainer_ciy57_23", $t = "_labelContent_ciy57_39", Mt = "_checkboxWrapper_ciy57_79", Bt = "_checkbox_ciy57_23", Ot = "_disabledLabel_ciy57_89", W = {
  checkmark: yt,
  checkboxContainer: At,
  labelContent: $t,
  checkboxWrapper: Mt,
  checkbox: Bt,
  disabledLabel: Ot
}, ct = (e) => {
  e.preventDefault();
}, ze = K(function({
  id: t,
  name: n,
  error: o = !1,
  classNames: r,
  children: i,
  required: s,
  checked: l,
  isDisabled: d,
  onChange: u,
  onClick: f,
  disabled: p,
  ...v
}, j) {
  const T = P(), h = t || n || T, _ = d || p;
  return /* @__PURE__ */ a.jsx(
    "label",
    {
      htmlFor: h,
      className: L(W.checkboxContainer, r == null ? void 0 : r.label, {
        [W.disabledLabel]: _
      }),
      children: /* @__PURE__ */ a.jsxs("div", { className: W.checkboxWrapper, children: [
        /* @__PURE__ */ a.jsxs("div", { className: W.checkbox, children: [
          /* @__PURE__ */ a.jsx(
            "input",
            {
              type: "checkbox",
              tabIndex: 0,
              ...v,
              ref: j,
              id: h,
              name: n,
              required: s,
              onClick: _ ? ct : f,
              onChange: _ ? ct : u,
              "aria-disabled": _,
              "aria-required": s,
              "aria-invalid": o,
              "aria-checked": l,
              "aria-describedby": o ? `${n}-error` : void 0
            }
          ),
          /* @__PURE__ */ a.jsx("span", { className: L(W.checkmark, r == null ? void 0 : r.checkmark) })
        ] }),
        /* @__PURE__ */ a.jsx("div", { className: L(W.labelContent, r == null ? void 0 : r.labelContent), children: i })
      ] })
    }
  );
}), Ft = "_fieldset_hid4k_1", Rt = "_fieldContainer_hid4k_30", Dt = "_groupLabel_hid4k_34", Pt = "_labelLarge_hid4k_39", Et = "_groupHint_hid4k_43", Wt = "_groupError_hid4k_48", qt = "_errorMessage_hid4k_57", zt = "_disabledLabels_hid4k_64", U = {
  fieldset: Ft,
  fieldContainer: Rt,
  groupLabel: Dt,
  labelLarge: Pt,
  groupHint: Et,
  groupError: Wt,
  errorMessage: qt,
  disabledLabels: zt
}, Ht = "_modal_36g8m_1", Ut = "_modalInner_36g8m_51", Kt = "_modalHeader_36g8m_55", Xt = "_closeButton_36g8m_70", Jt = "_closeIcon_36g8m_76", Yt = "_modalContent_36g8m_80", Gt = "_modalActions_36g8m_84", q = {
  modal: Ht,
  modalInner: Ut,
  modalHeader: Kt,
  closeButton: Xt,
  closeIcon: Jt,
  modalContent: Yt,
  modalActions: Gt
};
function He({
  id: e,
  isOpen: t,
  onClose: n,
  onOpen: o,
  onCloseComplete: r,
  onOpenComplete: i,
  title: s,
  customActions: l,
  children: d,
  manualFocusRef: u,
  closeText: f,
  closeOnClickOutside: p = !0,
  hideHeader: v = !1,
  hideActions: j = !1,
  classNames: T = {}
}) {
  const h = F(null), _ = F(null), [g, k] = O(!0), I = C(() => u || (_.current ? _ : h), [u]), B = P(), c = e || B, b = C((m) => {
    var x;
    if (m.key !== "Tab") return;
    const N = (x = h.current) == null ? void 0 : x.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    ), y = N == null ? void 0 : N[0], E = N == null ? void 0 : N[N.length - 1];
    if (m.shiftKey && document.activeElement === y) {
      m.preventDefault(), E == null || E.focus();
      return;
    }
    !m.shiftKey && document.activeElement === E && (m.preventDefault(), y == null || y.focus());
  }, []), w = C(() => {
    r == null || r(), k(!0);
  }, [r]), $ = C(() => {
    var m;
    i == null || i(), k(!0), (m = h.current) == null || m.removeAttribute("inert");
  }, [i]);
  M(() => {
    var N;
    k(!1);
    const m = h.current;
    if (m) {
      if (t) {
        m.showModal();
        const y = I();
        (N = y == null ? void 0 : y.current) == null || N.focus(), m.removeEventListener("transitionend", w), m.addEventListener("keydown", b), m.addEventListener("transitionend", $), o == null || o();
      } else
        m.removeEventListener("keydown", b), m.removeEventListener("transitionend", $), m.addEventListener("transitionend", w), m.close(), m.setAttribute("inert", "");
      return () => {
        m == null || m.removeEventListener("keydown", b), m.removeEventListener("transitionend", w), m.removeEventListener("transitionend", $);
      };
    }
  }, [I, b, t, w, o, $]);
  const X = C(
    (m) => {
      const { current: N } = h;
      m.target === N && n();
    },
    [n]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ a.jsx(
      "dialog",
      {
        id: c,
        ref: h,
        className: L(q.modal, T.modal),
        onCancel: n,
        onClick: p ? X : void 0,
        "aria-labelledby": `modal-title-${c}`,
        "aria-modal": "true",
        title: s,
        tabIndex: -1,
        children: /* @__PURE__ */ a.jsxs(ft, { space: "400", className: L(q.modalInner, T.modalInner), children: [
          !v && /* @__PURE__ */ a.jsxs("div", { className: L(q.modalHeader, T.titleContainer), children: [
            /* @__PURE__ */ a.jsx(wt, { level: "h200", as: "h2", id: `modal-title-${c}`, children: s }),
            /* @__PURE__ */ a.jsx(
              kt,
              {
                kind: "plain",
                className: q.closeButton,
                onClick: n,
                ref: _,
                type: "button",
                iconName: "x",
                iconProps: { title: f, color: "fg-content-primary" }
              }
            )
          ] }),
          /* @__PURE__ */ a.jsx("div", { className: L(q.modalContent, T.contentContainer), children: (t || !g) && d }),
          !j && /* @__PURE__ */ a.jsx("div", { className: L(q.modalActions, T.actionsContainer), children: l || /* @__PURE__ */ a.jsx(Tt, { onClick: n, type: "button", children: f }) })
        ] })
      }
    )
  );
}
const Qt = "_cluster_1iwsd_1", Vt = "_clustered_1iwsd_7", Zt = "_justified_1iwsd_11", Z = {
  cluster: Qt,
  clustered: Vt,
  justified: Zt
};
function St({
  as: e = "div",
  alignment: t = "clustered",
  className: n,
  style: o,
  space: r = "200",
  ...i
}) {
  return /* @__PURE__ */ a.jsx(
    e,
    {
      ...i,
      className: L(Z.cluster, n, {
        [Z.justified]: t === "justified",
        [Z.clustered]: t === "clustered"
      }),
      style: {
        ...o,
        "--space": `var(--betterplace-spacing-${r})`
      },
      children: i.children
    }
  );
}
function te({ className: e, hint: t, required: n, children: o }) {
  return /* @__PURE__ */ a.jsxs("legend", { className: e, children: [
    /* @__PURE__ */ a.jsxs("div", { className: U.groupLabel, children: [
      o,
      n && /* @__PURE__ */ a.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    t ? /* @__PURE__ */ a.jsx("div", { className: U.groupHint, children: t }) : null
  ] });
}
function Ue({
  children: e,
  classNames: t,
  legend: n,
  hint: o,
  required: r,
  error: i,
  layout: s = "horizontal",
  layoutProps: l = {
    space: "none"
  },
  errorDisplay: d = "all",
  ...u
}) {
  const f = L(U.fieldContainer, l == null ? void 0 : l.className, {
    [U.groupError]: !!i && d !== "text"
  }), p = s === "vertical" ? ft : St, v = P(), j = u.id || v, T = i ? `${j}-error` : void 0;
  return /* @__PURE__ */ a.jsxs(
    "fieldset",
    {
      id: j,
      className: L(U.fieldset, t == null ? void 0 : t.fieldset),
      "aria-describedby": T,
      ...u,
      children: [
        n || o ? /* @__PURE__ */ a.jsx(te, { required: r, hint: o, className: t == null ? void 0 : t.legend, children: n }) : null,
        /* @__PURE__ */ a.jsx(p, { className: f, ...l, children: e }),
        i && d !== "border" ? /* @__PURE__ */ a.jsx("p", { id: T, className: U.errorMessage, "aria-live": "polite", role: "alert", children: i }) : null
      ]
    }
  );
}
const ee = "_selectContainer_c6pfo_1", ne = "_select_c6pfo_1", oe = "_label_c6pfo_43", re = "_hint_c6pfo_50", J = {
  selectContainer: ee,
  select: ne,
  label: oe,
  hint: re
}, Ke = K(function({ id: t, name: n, children: o, label: r, hint: i, required: s, ...l }, d) {
  const u = P(), f = t || n || u;
  return /* @__PURE__ */ a.jsxs("div", { children: [
    /* @__PURE__ */ a.jsx("div", { children: r ? /* @__PURE__ */ a.jsxs("label", { htmlFor: f, className: L(J.label), id: `${f}-label`, children: [
      /* @__PURE__ */ a.jsxs("div", { children: [
        r,
        s && /* @__PURE__ */ a.jsx("span", { "aria-label": "(required)", children: "*" })
      ] }),
      i ? /* @__PURE__ */ a.jsx("div", { className: L(J.hint), id: `${f}-hint`, children: i }) : null
    ] }) : null }),
    /* @__PURE__ */ a.jsxs("div", { className: J.selectContainer, children: [
      /* @__PURE__ */ a.jsx("select", { ref: d, className: J.select, id: f, name: n, ...l, children: o }),
      /* @__PURE__ */ a.jsx(mt, { name: "arrowDown" })
    ] })
  ] });
}), ie = "_radioContainer_p0kaq_1", se = "_label_p0kaq_16", ae = "_radioCircle_p0kaq_26", ce = "_radioWrapper_p0kaq_54", le = "_radio_p0kaq_1", de = "_disabledLabel_p0kaq_64", z = {
  radioContainer: ie,
  label: se,
  radioCircle: ae,
  radioWrapper: ce,
  radio: le,
  disabledLabel: de
}, ue = (e) => /* @__PURE__ */ V.createElement("svg", { width: 27, height: 28, viewBox: "0 0 27 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...e }, /* @__PURE__ */ V.createElement("circle", { cx: 13.5, cy: 14.21, r: 12.9375, fill: "white", stroke: "#919191", strokeWidth: 1.125 }), /* @__PURE__ */ V.createElement("circle", { cx: 13.4882, cy: 14.2689, r: 6.75, fill: "#357C00" })), lt = (e) => {
  e.preventDefault();
}, Xe = K(function({ children: t, isDisabled: n = !1, checked: o, classNames: r, disabled: i, ...s }, l) {
  const { value: d, name: u, id: f, onChange: p, onClick: v } = s, j = P(), h = `${f || u || j}-${String(d)}`, _ = n || i;
  return /* @__PURE__ */ a.jsx(
    "label",
    {
      htmlFor: h,
      className: L(z.radioContainer, r == null ? void 0 : r.container, {
        [z.disabledLabel]: _
      }),
      children: /* @__PURE__ */ a.jsxs("div", { className: z.radioWrapper, children: [
        /* @__PURE__ */ a.jsxs("div", { className: z.radio, children: [
          /* @__PURE__ */ a.jsx(
            "input",
            {
              ...s,
              id: h,
              type: "radio",
              ref: l,
              "aria-disabled": _,
              "aria-checked": o,
              onClick: _ ? lt : v,
              onChange: _ ? lt : p
            }
          ),
          /* @__PURE__ */ a.jsx(ue, { className: L(z.radioCircle, r == null ? void 0 : r.radioCircle) })
        ] }),
        /* @__PURE__ */ a.jsx("div", { className: L(z.label, r == null ? void 0 : r.label), children: t })
      ] })
    }
  );
}), fe = "_input_xcovg_1", me = "_growWrap_xcovg_2", Y = {
  input: fe,
  growWrap: me,
  "input--error": "_input--error_xcovg_24"
}, Je = K(function({ label: t, name: n, description: o, error: r, warning: i, id: s, required: l, className: d, onChange: u, errorDisplay: f, rows: p = 1, ...v }, j) {
  const T = F(null), h = [];
  return r && h.push(`${n}-error`), i && h.push(`${n}-warning`), o && h.push(`${n}-description`), /* @__PURE__ */ a.jsx(
    Ct,
    {
      label: t,
      name: n,
      description: o,
      error: r,
      warning: i,
      id: s || n,
      required: l,
      errorDisplay: f,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ a.jsx("div", { className: L(Y.growWrap, d, { [Y.wrapError]: !!r }), ref: T, children: /* @__PURE__ */ a.jsx(
        "textarea",
        {
          ...v,
          ref: j,
          id: s || n,
          name: n,
          "aria-describedby": h.length ? h.join(" ") : void 0,
          "aria-invalid": !!r,
          "aria-required": l,
          className: L(Y.input, d, { [Y["input--error"]]: !!r }),
          rows: p,
          onChange: (_) => {
            var g;
            (g = T.current) == null || g.setAttribute("data-replicated-value", _.target.value), u && u(_);
          }
        }
      ) })
    }
  );
}), pe = "_button_akv9v_1", be = "_hiddenInput_akv9v_62", S = {
  button: pe,
  hiddenInput: be,
  "size-large": "_size-large_akv9v_68",
  "size-default": "_size-default_akv9v_73",
  "size-small": "_size-small_akv9v_78"
}, dt = (e) => {
  e.preventDefault();
}, Ye = K(function({
  id: t,
  children: n,
  value: o,
  name: r,
  onChange: i,
  onClick: s,
  className: l,
  type: d = "radio",
  size: u = "default",
  isDisabled: f = !1,
  disabled: p,
  ...v
}, j) {
  const T = L(S.button, S[`size-${u}`], l), h = P(), g = `${t || r || h}-${String(o)}`, k = f || p;
  return /* @__PURE__ */ a.jsxs("label", { htmlFor: g, className: T, children: [
    /* @__PURE__ */ a.jsx(
      "input",
      {
        ...v,
        ref: j,
        id: g,
        "aria-disabled": k,
        type: d,
        value: o,
        name: r,
        onClick: k ? dt : s,
        onChange: k ? dt : i,
        className: S.hiddenInput
      }
    ),
    n
  ] });
}), he = "_tooltipContent_15m5t_1", _e = "_tooltipContainer_15m5t_11", xe = "_tooltipArrow_15m5t_19", ge = "_bottomTooltipArrow_15m5t_50", we = "_topTooltipArrow_15m5t_54", ke = "_spyElement_15m5t_58", Te = "_triggerContainer_15m5t_64", Ce = "_nonFocusableOutline_15m5t_74", R = {
  tooltipContent: he,
  tooltipContainer: _e,
  tooltipArrow: xe,
  bottomTooltipArrow: ge,
  topTooltipArrow: we,
  spyElement: ke,
  triggerContainer: Te,
  nonFocusableOutline: Ce
}, D = {
  TOP: "top",
  BOTTOM: "bottom"
}, ve = {
  a: "link",
  area: "link",
  article: "article",
  aside: "complementary",
  button: "button",
  details: "group",
  dfn: "term",
  dialog: "dialog",
  fieldset: "group",
  figure: "figure",
  footer: "contentinfo",
  form: "form",
  h1: "heading",
  h2: "heading",
  h3: "heading",
  h4: "heading",
  h5: "heading",
  h6: "heading",
  header: "banner",
  hr: "separator",
  iframe: "document",
  img: "img",
  input: null,
  // for type purposes this value needs to be included
  li: "listitem",
  main: "main",
  menu: "list",
  menuitem: "menuitem",
  meter: "meter",
  nav: "navigation",
  ol: "list",
  option: "option",
  progress: "progressbar",
  section: "region",
  select: "listbox",
  summary: "button",
  table: "table",
  tbody: "rowgroup",
  td: "cell",
  textarea: "textbox",
  tfoot: "rowgroup",
  th: "columnheader",
  thead: "rowgroup",
  tr: "row",
  ul: "list"
}, Le = {
  button: "button",
  checkbox: "checkbox",
  radio: "radio",
  range: "slider",
  text: "textbox",
  password: "textbox",
  email: "textbox",
  search: "searchbox",
  number: "spinbutton",
  tel: "textbox",
  url: "textbox",
  submit: "button",
  reset: "button",
  image: "button",
  file: "textbox"
}, je = ["INPUT", "SELECT", "TEXTAREA", "A", "BUTTON", "AREA"];
function Ne(e) {
  return !e || !e.trim() ? /* @__PURE__ */ new Set() : new Set(e.trim().split(/\s+/));
}
function Ie(e, t) {
  return [...Ne(e).add(t)].join(" ");
}
function ye(e) {
  const t = e.getAttribute("role");
  if (t) return t;
  const n = e.tagName.toLowerCase();
  if (n === "input") {
    const r = e.getAttribute("type");
    return r ? Le[r] : "textbox";
  }
  const o = ve[n];
  return o || "button";
}
function bt(e) {
  if (!e || !(e instanceof HTMLElement)) return null;
  if (je.includes(e.tagName))
    return e;
  for (const t of e.children) {
    const n = bt(t);
    if (n)
      return n;
  }
  return null;
}
function Ae(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function ht(e) {
  return (t) => t === e || t && Ae(t, e) ? t : e;
}
const tt = {
  enter: "Enter",
  space: " ",
  tab: "Tab",
  escape: "Escape"
};
function ut({ x: e, position: t }) {
  return /* @__PURE__ */ a.jsx(
    "div",
    {
      className: L(R.tooltipArrow, {
        [R.bottomTooltipArrow]: t === D.BOTTOM,
        [R.topTooltipArrow]: t === D.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === D.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
function $e() {
  const e = document.getElementById("bp-portal-target");
  if (e) return e;
  const t = document.createElement("div");
  return t.id = "bp-portal-target", t.style.position = "absolute", t.setAttribute("role", "tooltip"), document.body.appendChild(t), t;
}
function Me({
  triggerRect: e,
  id: t,
  onTooltipLeave: n,
  children: o,
  triggerEvents: r
}) {
  const i = F(null), [s, l] = O(null), [d, u] = O(null), f = C(() => {
    var I;
    const k = ((I = i == null ? void 0 : i.current) == null ? void 0 : I.getBoundingClientRect()) ?? null;
    l(ht(k && { width: k.width, height: k.height }));
  }, []), p = C(() => {
    const k = document.body.getBoundingClientRect();
    u(k.width);
  }, []);
  pt(() => {
    f(), p();
  }, [f, p]);
  const v = C(() => {
    window.requestAnimationFrame(() => {
      f(), p();
    });
  }, [f, p]), j = C(
    (k) => {
      r != null && r.includes("hover") && n(k);
    },
    [n, r]
  );
  M(() => (window.addEventListener("resize", v), () => {
    window.removeEventListener("resize", v);
  }), [v]);
  const { x: T, y: h, arrowTooltipOffsetX: _, position: g } = H(
    () => Oe(e, s, d),
    [e, s, d]
  );
  return vt(
    /* @__PURE__ */ a.jsxs(
      "div",
      {
        id: t,
        className: R.tooltipContainer,
        ref: i,
        onPointerLeave: j,
        style: {
          transform: `translate3d(${T}px, ${h}px, 0)`
        },
        children: [
          g === D.BOTTOM && /* @__PURE__ */ a.jsx(ut, { position: g, x: _ }),
          /* @__PURE__ */ a.jsx("div", { className: R.tooltipContent, children: o }),
          g === D.TOP && /* @__PURE__ */ a.jsx(ut, { position: g, x: _ })
        ]
      }
    ),
    $e()
  );
}
function Be({ children: e, content: t, id: n, triggerEvents: o }) {
  const r = P(), i = n || r, s = F(null), [l, d] = O(null);
  M(() => {
    var y;
    const c = (y = s.current) == null ? void 0 : y.nextSibling;
    if (!c) return;
    const b = bt(c), w = b ?? c, X = w.getAttribute("aria-roledescription") ?? ye(w) ?? "button";
    w.setAttribute("role", "button"), b || (w == null || w.setAttribute("tabindex", "0"), w == null || w.classList.add(R.nonFocusableOutline)), w.setAttribute("aria-roledescription", X);
    const m = w.getAttribute("aria-describedby");
    (m == null ? void 0 : m.includes(i)) || w.setAttribute("aria-describedby", Ie(m, i));
  }, [e, i]);
  const u = C(() => {
    var w;
    const c = (w = s == null ? void 0 : s.current) == null ? void 0 : w.nextSibling;
    let b = (c == null ? void 0 : c.getBoundingClientRect()) ?? null;
    b != null && b.height || (b = null), d(ht(b && { left: b.left, top: b.top, bottom: b.bottom, width: b.width }));
  }, [s]), f = C(() => {
    l && u();
  }, [u, l]), p = C(() => {
    d(null);
  }, []), v = C(() => {
    l ? p() : u();
  }, [u, p, l]), j = C(
    (c) => {
      const b = document.getElementById(r);
      if (c.relatedTarget instanceof Node && (b != null && b.contains(c.relatedTarget)))
        return c.preventDefault();
      p();
    },
    [p, r]
  ), T = C(
    (c) => {
      var b, w;
      if (c.relatedTarget instanceof Node && ((w = (b = s == null ? void 0 : s.current) == null ? void 0 : b.nextSibling) != null && w.contains(c.relatedTarget)))
        return c.preventDefault();
      p();
    },
    [p]
  ), h = C(
    (c) => {
      o != null && o.includes("click") && (c.preventDefault(), v());
    },
    [v, o]
  ), _ = C(
    (c) => {
      if (o != null && o.includes("keypress") && (c.key === tt.enter || c.key === tt.space)) {
        c.preventDefault(), v();
        return;
      }
      c.key === tt.escape && p();
    },
    [p, v, o]
  ), g = C(() => {
    o != null && o.includes("focus") && u();
  }, [u, o]), k = C(() => {
    o != null && o.includes("focus") && p();
  }, [p, o]), I = C(() => {
    o != null && o.includes("hover") && u();
  }, [u, o]), B = C(
    (c) => {
      o != null && o.includes("hover") && j(c);
    },
    [j, o]
  );
  return M(() => {
    var b;
    const c = (b = s.current) == null ? void 0 : b.nextSibling;
    return c == null || c.addEventListener("click", h), c.addEventListener("pointerenter", I), c.addEventListener("pointerleave", B), c.addEventListener("focus", g), c.addEventListener("focusin", g), c.addEventListener("focusout", k), c.addEventListener("keydown", _), () => {
      c.removeEventListener("click", h), c.removeEventListener("pointerenter", I), c.removeEventListener("pointerleave", B), c.removeEventListener("focus", g), c.removeEventListener("focusin", g), c.removeEventListener("focusout", k), c.removeEventListener("keydown", _);
    };
  }, [h, g, _, I, B, v, s, k, e]), M(() => (window.addEventListener("resize", f), () => {
    window.removeEventListener("resize", f);
  }), [f]), pt(() => {
    f();
  }, [e, f]), /* @__PURE__ */ a.jsxs(a.Fragment, { children: [
    /* @__PURE__ */ a.jsx("div", { ref: s, className: R.spyElement }),
    e,
    l && /* @__PURE__ */ a.jsx(
      Me,
      {
        triggerRect: l,
        id: i,
        onTooltipLeave: T,
        triggerEvents: o,
        children: t
      }
    )
  ] });
}
function Ge({ children: e, content: t, id: n, triggerEvents: o = ["hover", "focus"], inactive: r }) {
  const [i, s] = O(!1);
  return M(() => (s(!0), () => {
    s(!1);
  }), []), !i || r ? e : /* @__PURE__ */ a.jsx(Be, { content: t, id: n, triggerEvents: o, children: e });
}
function Oe(e, t, n, o = 20, r = 17) {
  let i = 0, s = 0, l = 0, d = 0, u = D.TOP;
  if (!e || !t || !n) return { x: i, y: s, arrowTooltipOffsetX: d, position: u };
  const f = t.width > n ? 0 : Math.min(o, (n - t.width) / 2), p = t.width / 2, v = e.width / 2;
  i = e.left - p + v;
  const j = i;
  l = i + t.width, i < f ? i = f : l > n - f && (i -= l - (n - f)), d = j - i + p - r;
  const T = d + 2 * r - t.width;
  if (T > 0) {
    const h = Math.min(T, f);
    i += h, d -= h;
  }
  return s = e.top - t.height, s < 0 && (u = D.BOTTOM, s = e.bottom), { x: i, y: s, arrowTooltipOffsetX: d, position: u };
}
const Fe = "_tooltipTrigger_36bik_1", Re = {
  tooltipTrigger: Fe
}, Qe = ({ type: e = "question", title: t = "More information", className: n }) => /* @__PURE__ */ a.jsx("button", { className: L(Re.tooltipTrigger, n), type: "button", children: /* @__PURE__ */ a.jsx(mt, { name: e, title: t }) });
function De(e, t, n) {
  const o = F(null), r = F(null), [i, s] = O(null);
  return M(() => {
    r.current = t ?? null;
  }, [t]), M(() => (o.current = new MutationObserver((l) => {
    for (const d of l)
      (typeof r.current != "function" || r.current(d)) && s(d);
  }), o.current.observe(e.current, n), () => {
    var l;
    (l = o.current) == null || l.disconnect();
  }), []), i;
}
function Ve(e, t) {
  const n = C(
    (i) => i.type === "attributes" && i.attributeName === t,
    [t]
  ), o = De(e, n, { attributes: !0 });
  return H(
    () => {
      var i;
      return { oldValue: (o == null ? void 0 : o.oldValue) ?? null, newValue: (i = e.current) == null ? void 0 : i.getAttribute(t) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [o, t]
  );
}
export {
  tn as Alert,
  qe as AllowedInputTypes,
  Nt as AmountField,
  en as Badge,
  Tt as Button,
  nn as Card,
  ze as Checkbox,
  Ke as Dropdown,
  Ue as Fieldset,
  wt as Heading,
  mt as Icon,
  kt as IconButton,
  on as JSONPrettyPrint,
  rn as LayoutCenter,
  St as LayoutCluster,
  He as LayoutModal,
  ft as LayoutStack,
  D as Position,
  Xe as RadioButton,
  xt as TextInput,
  sn as TextLink,
  Je as Textarea,
  Ye as ToggleButton,
  Ge as Tooltip,
  Qe as TooltipTrigger,
  Ve as useAttributeObserver,
  De as useMutationObserver
};
